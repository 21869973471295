import React from 'react';
import styled from 'styled-components';

export interface ButtonProps {
  // Text in the button
  text?: string;

  // Height of the button (em)
  height: string;

  // Width of the button (em)
  width: string;

  // Border coloring
  // borderColor?: string;

  // Background color (set default as transparent, being = '')
  backgroundColor?: string;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  text = '',
  height,
  width,
  backgroundColor = '',
}) => (
  <Container height={height} width={width}>
    <ButtonItself
      height={height}
      width={width}
      backgroundColor={backgroundColor}
    >
      {`${text}`}
    </ButtonItself>
  </Container>
);
export default Button;

// Styled component named StyledButton
const Container = styled.div`
  height: ${(props: { height: string; width: string }) => props.height};
  width: ${(props: { height: string; width: string }) => props.width};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin: 1em;
`;

const ButtonItself = styled.button`
  font-size: 1.5em;
  text-align: center;
  height: ${(props: {
    backgroundColor: string;
    height: string;
    width: string;
  }) => props.height};
  width: ${(props: {
    backgroundColor: string;
    height: string;
    width: string;
  }) => props.width};
  border: 3em;
  color: white;
  background-color: ${(props: {
    backgroundColor: string;
    height: string;
    width: string;
  }) => props.backgroundColor};
  font-family: Lucida Grande;
  border-radius: 15px;
`;
