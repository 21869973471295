/* eslint-disable react/no-unknown-property */
import { Canvas } from '@react-three/fiber';
import React, { Suspense } from 'react';
import styled from 'styled-components';

export interface AvatarContainerProps {
  // background color (set default as transparent, being = '')
  backgroundColor?: string;
}

const AvatarContainer: React.FunctionComponent<AvatarContainerProps> = ({
  backgroundColor = '',
}) => (
  <Container backgroundColor={backgroundColor}>
    <AvatarItself />
  </Container>
);
export default AvatarContainer;

// Styled component named StyledButton
const Container = styled.div`
  background: ${(props: { backgroundColor: string }) => props.backgroundColor};
  width: 5em;
  height: 7em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

// Styled component named Logo
const AvatarItself = styled.div`
  flex: 1;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
`;
