/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styled from 'styled-components';
import Button from '../Button/Button';

export interface CommandBarProps {
  // background color (set default as transparent, being = '')
  backgroundColor?: string;

  username?: string;

  // TODO: this is an optional field since (eventually) we will have it be so we can open home navigation WITHOUT being logged in, this would be
  // an added field for a "Login" click functionality to be added on this home menu when NOT logged in.
  onLogoutClick?: React.MouseEventHandler<HTMLDivElement>;
}

const CommandBar: React.FunctionComponent<CommandBarProps> = ({
  backgroundColor = '',
  onLogoutClick,
  username,
}) => (
  <CommandBarContainer backgroundColor={backgroundColor}>
    <Button
      height='2.5em'
      width='10em'
      text='Profile'
      backgroundColor='green'
    />
    <Button
      height='2.5em'
      width='10em'
      text='Catalog'
      backgroundColor='orange'
    />
    <Button
      height='2.5em'
      width='10em'
      text='Settings'
      backgroundColor='blue'
    />
    <UserInformationContainer> {username}</UserInformationContainer>
    <div className='logout_user_ctn' onClick={onLogoutClick}>
      <Button
        height='2.5em'
        width='10em'
        text='Logout'
        backgroundColor='purple'
      />
    </div>
  </CommandBarContainer>
);
export default CommandBar;

// Styled component named StyledButton
const CommandBarContainer = styled.div`
  background: ${(props: { backgroundColor: string }) => props.backgroundColor};
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 4.5em;
  top: 0;
`;

const UserInformationContainer = styled.div`
  background: red;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 4.5em;
  top: 0;
`;
