import React from 'react';
import styled from 'styled-components';

export interface LogoProps {
  // Image url of the logo
  imageUrl: string;

  // Height of the logo (em)
  height: string;

  // Width of the logo (em)
  width: string;

  // Background color (set default as transparent, being = '')
  backgroundColor?: string;
}

const Logo: React.FunctionComponent<LogoProps> = ({
  imageUrl,
  height,
  width,
  backgroundColor = '',
}) => (
  <Container height={height} width={width} backgroundColor={backgroundColor}>
    <LogoItself height={height} width={width} imageUrl={imageUrl} />
  </Container>
);
export default Logo;

// Styled component named StyledButton
const Container = styled.div`
  background: ${(props: {
    backgroundColor: string;
    height: string;
    width: string;
  }) => props.backgroundColor};
  height: ${(props: {
    backgroundColor: string;
    height: string;
    width: string;
  }) => props.height};
  width: ${(props: {
    backgroundColor: string;
    height: string;
    width: string;
  }) => props.width};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

// Styled component named Logo
const LogoItself = styled.div`
  background-image: url(${(props: {
    imageUrl: string;
    height: string;
    width: string;
  }) => props.imageUrl});
  height: ${(props: { imageUrl: string; height: string; width: string }) =>
    props.height};
  width: ${(props: { imageUrl: string; height: string; width: string }) =>
    props.width};
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
`;
