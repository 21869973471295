import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { auth } from '../../utils/firebaseConfig.js';

const PrivateRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    // TODO: This will be our loading screen, for now it's blank green background.
    return <Background />;
  }

  return user ? children : <Navigate to='/' />;
};

export default PrivateRoute;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: cover;
  top: 0;
  left: 0;
  background-color: #107361;
  display: flex;
  flex-direction: column;
`;
