/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './views/HomePage/HomePage';
import LoggedInView from './views/LoggedInView/LoggedInView';
import PrivateRoute from './components/PrivateRoute';

const App = () => {
  return (
    <div className='app'>
      <Router>
        <Routes>
          <Route exac path='/' element={<HomePage />} />
          <Route
            exac
            path='/home'
            element={
              <PrivateRoute>
                <LoggedInView />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
