/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import styled from 'styled-components';
import {
  auth,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
} from '../../utils/firebaseConfig.js';

import logoImg from '../../assets/drillbytelogo.png';

import Logo from '../../components/Logo/Logo.tsx';
import Button from '../../components/Button/Button.tsx';

const HomePage = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [createUserVisible, setCreateUserVisible] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const infoTextFields = {
    loginTitle: 'This is test type to show the user for login!',
    loginQuestionTitle: 'Already have an account? Login now!',
    createAccountTitle: 'Create an account below!',
    createAccountQuestionTitle:
      "Don't have an account? Create one to get started!",
  };

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      navigate('/home');
    }
  }, [user, loading]);

  const handleSwitchingUserScrClick = () => {
    // Clear fields
    setUsername('');
    setPassword('');
    setEmail('');
    // Switch the Modal from being Login Acc config to Create Acc config back & forth
    setCreateUserVisible(!createUserVisible);
  };

  return (
    <Background>
      <Header>
        <Logo height='300px' width='500px' imageUrl={logoImg} />
      </Header>
      <Modal>
        {
          // Login account menu open
          !createUserVisible && (
            <InputCard>
              <InputCardTitleText>
                {infoTextFields.loginTitle}
              </InputCardTitleText>
              <TextInput
                maxLength={320}
                type='text'
                placeholder='Username'
                value={email}
                onChange={input => setEmail(input.target.value)}
              />
              <div />
              <TextInput
                type='password'
                value={password}
                onChange={input => setPassword(input.target.value)}
                placeholder='Password'
              />
              <div
                className='login_user_ctn'
                onClick={() => logInWithEmailAndPassword(email, password)}
              >
                <Button
                  text='Login'
                  backgroundColor='green'
                  height='2.25em'
                  width='28.75em'
                />
              </div>
              <ErrorText>
                {error ? 'Invalid email/password credentials' : ''}
              </ErrorText>
              <CreateTitleText>
                {infoTextFields.loginQuestionTitle}
              </CreateTitleText>
              <div
                className='create_user_ctn'
                onClick={() => handleSwitchingUserScrClick()}
              >
                <Button
                  text='Create Account'
                  backgroundColor='green'
                  height='2.25em'
                  width='28.75em'
                />
              </div>
            </InputCard>
          )
        }
        {
          // Create account menu open
          createUserVisible && (
            <InputCard>
              <InputCardTitleText>
                {infoTextFields.createAccountTitle}
              </InputCardTitleText>
              <TextInput
                maxLength={320}
                type='text'
                placeholder='Username'
                value={username}
                onChange={input => setUsername(input.target.value)}
              />
              <TextInput
                maxLength={320}
                type='text'
                placeholder='E-Mail'
                value={email}
                onChange={input => setEmail(input.target.value)}
              />
              <div />
              <TextInput
                type='password'
                value={password}
                onChange={input => setPassword(input.target.value)}
                placeholder='Password'
              />
              <div
                className='create_user_ctn'
                onClick={() =>
                  registerWithEmailAndPassword(username, email, password)
                }
              >
                <Button
                  text='Create Account'
                  backgroundColor='green'
                  height='2.25em'
                  width='28.75em'
                />
              </div>
              <ErrorText>
                {error ? 'Invalid email/password credentials' : ''}
              </ErrorText>
              <CreateTitleText>
                {infoTextFields.createAccountQuestionTitle}
              </CreateTitleText>
              <div
                className='login_user_ctn'
                onClick={() => handleSwitchingUserScrClick()}
              >
                <Button
                  text='Login Now'
                  backgroundColor='green'
                  height='2.25em'
                  width='28.75em'
                />
              </div>
            </InputCard>
          )
        }
      </Modal>
      <BottomHeader />
    </Background>
  );
};

export default HomePage;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: cover;
  top: 0;
  left: 0;
  background-color: #107361;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h1`
  padding-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%
  min-height: 100em;
  height: 100em;
  flex: 15;
  padding-bottom: 0em;
`;

const Modal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width 100%;
  height: 100%;
  margin-top: 0em;
  flex-direction: row;
  flex: 30;
`;

// Create a Title component that'll render an <h1> tag with some styles
const InputCard = styled.div`
  justify-content: center;
  min-width: 20em;
  background-color: #0a3a40;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  padding-top: 25px;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 15px;
  margin: auto;
`;

const InputCardTitleText = styled.div`
  text-align: center;
  height: 100%;
  margin-top: 3%;
  margin-bottom: 2%;
  font-size: 1.2em;
  color: white;
  font-family: Lucida Grande;
  padding: 10px;
  display: block;
`;

const BottomHeader = styled.div`
  flex: 1;
  background-color: #042326;
  width: 100%;
`;

const TextInput = styled.input`
  margin-bottom: 5px;
  margin-top: 5px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 3px solid #ccc;
  outline: none;
  border-radius: 15px;
  color: black;
  font-family: Lucida Grande;
  font-size: 1.5em;
  padding: 10px;
  ::placeholder {
    color: grey;
    font-family: Lucida Grande;
  }
`;

const LoginButton = styled.button`
  font-size: 2em;
  text-align: center;
  height: 100%;
  width: 100%;
  border: 3em;
  background-color: #107361;
  font-family: Lucida Grande;
  color: white;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
`;

const ErrorText = styled.div`
  font-size: 1.2em;
  color: red;
  font-family: Lucida Grande;
  text-align: center;
`;

const CreateTitleText = styled.div`
  font-size: 1em;
  color: white;
  font-family: Lucida Grande;
  text-align: center;
`;

const CreateAccountButton = styled.button`
  font-size: 1.2em;
  text-align: center;
  height: 100%;
  border: 3em;
  background-color: #107361;
  font-family: Lucida Grande;
  color: white;
  border-radius: 15px;
  margin: 20px;
  margin-bottom: 40px;
  margin-top: 5px;
  padding: 10px;
`;
