import React from 'react';
import styled from 'styled-components';
import AvatarContainer from '../AvatarContainer/AvatarContainer';

export interface CommandBarProps {
  // options for the command bar
  username: string;

  // background color (set default as transparent, being = '')
  backgroundColor?: string;
}

const CommandBar: React.FunctionComponent<CommandBarProps> = ({
  username = 'jlutz',
  backgroundColor = '',
}) => (
  <Container backgroundColor={backgroundColor}>
    <ProfileModal>
      <UsernameContainer>{username}</UsernameContainer>
      <ProfilePictureContainer>
        <AvatarContainer backgroundColor='blue' />
      </ProfilePictureContainer>
    </ProfileModal>
  </Container>
);
export default CommandBar;

// Styled component named StyledButton
const Container = styled.div`
  background: ${(props: { backgroundColor: string }) => props.backgroundColor};
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 5em;

  width: 100%;
  border-radius: 1em;
  display: flex;
  min-width: 20em;
  align-items: center;
  flex-direction: column;
`;

const ProfileModal = styled.div`
  border-radius: 1em;
  background-color: gray;
  flex: 1;
  justify-content: center;
  width: 85%;
  height: 20em;
  padding-bottom: 5px;
`;

const UsernameContainer = styled.div`
  border-radius: 1em 1em 0em 0em;
  background-color: #404040;
  flex: 1;
  justify-content: center;
  width: 100%;
  color: white;
  height: 2.25em;
  padding-top: 0.5em;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProfilePictureContainer = styled.div`
  border-radius: 0em 0em 1em 1em;
  background-color: white;
  flex: 1;
  justify-content: center;
  width: 100%;
  color: white;
  height: 8em;
  padding-top: 0.5em;
  display: flex;
`;

const CommandButton = styled.button`
  font-size: em;
  text-align: center;
  height: 80%;
  width: 10%;
  background-color: #808080;
  font-family: Lucida Grande;
  color: white;
  border: none;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 5px;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    background-color: #424043;
  }
`;
