import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import CommandBar from '../../components/CommandBar/CommandBar.tsx';
import CommandBarItem from '../../components/CommandBarItem/CommandBarItem.tsx';
import Button from '../../components/Button/Button.tsx';

import ProfilePage from '../../components/ProfilePage/ProfilePage.tsx';
import { auth, logout } from '../../utils/firebaseConfig.js';

const LoggedInView = () => {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      <div>loading</div>;
    }
  }, [user, loading, navigate]);

  const handleLogout = () => {
    // eslint-disable-next-line no-console
    console.log(`Logging out:${user.email}`);
    navigate('/');
    logout();
  };

  return (
    <Background>
      <CommandBar
        backgroundColor='#BFBFBF'
        username={`User's Email: ${user.email}`}
        // eslint-disable-next-line no-console
        onLogoutClick={() => handleLogout()}
      />
    </Background>
  );
};

export default LoggedInView;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: cover;
  top: 0;
  left: 0;
  background-color: #107361;
  display: flex;
  flex-direction: column;
`;
